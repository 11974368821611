<template>
    <v-container>
        <h1 class="mb-10">{{name}}</h1>
        <v-data-table
            :loading="!isDownloaded"
            :headers="headers"
            :items="tasks"
            class="elevation-2"
            :sort-desc="false"
            disable-pagination
            item-key="_id"
            hide-default-footer
        >
            <template v-slot:item.status="{ item }">
				<StatusChip :status="item.status" />
            </template>
            <template v-slot:item.actions="{ item }">
				<div class="d-flex align-center">
					<v-btn
						color="green"
						class="mt-1 mr-3 mb-1 white--text"
						@click.stop="changeRoute(item._id)"
					>
						Zobacz
					</v-btn>
                    <EditSingleApprenticeTask 
                        small 
                        :id="item._id"
                        :task="item"
                        :apprenticeId="$route.params.id"
                        v-if="role === 'administrator' || role === 'moderator'"
                    />
                    <RemoveSingleApprenticeTask 
                        small 
                        :id="item._id"
                        :apprenticeId="$route.params.id"
                        v-if="role === 'administrator' || role === 'moderator'"
                    />
				</div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import StatusChip from '@/components/molecules/StatusChip.vue';
import RemoveSingleApprenticeTask from '@/components/molecules/RemoveSingleApprenticeTask.vue';
import EditSingleApprenticeTask from '@/components/molecules/EditSingleApprenticeTask.vue';
import io from 'socket.io-client';

export default {
    components: {
		StatusChip,
        RemoveSingleApprenticeTask,
        EditSingleApprenticeTask
	},
    computed: {
        role(){
            return this.$store.getters.getUserRole;
		},
    },
    data(){
        return {
            socket: io(this.url),
            id: this.$route.params.id,
            tasks: [],
            name: null,
            isDownloaded: false,
            headers: [
                { text: 'Zadanie', align: 'left', sortable: true, value: 'title', },
                { text: 'Znaki', align: 'left', sortable: true, value: 'characters', },
                { text: 'Status', value: 'status' , sortable: true },
                { text: 'Akcje', value: 'actions', sortable: false },
            ],
        }
    },
    methods:{
		getTask(){
            this.$axios.get(`${this.url}api/apprentice/${this.id}`)
            .then(resp => {
				this.tasks = resp.data.tasks;
                this.name = resp.data.name;
				this.isDownloaded = true;
			})
		},
        changeRoute(_id){
            this.$router.push(`${this.id}/task/${_id}`);
        },
    
    },
    created(){
        this.getTask();

        this.socket.on('apprenticeTaskDeleted', () => {
            this.getTask();

        });
        this.socket.on('appreticeChangedStatus', () => {
            this.getTask();
        });
        this.socket.on('editedSingleApprenticeTask', () => {
            this.getTask();
        });
        this.socket.on('deletedSingleApprenticeTask', () => {
            this.getTask();
        });
    }
}
</script>

<style>

</style>